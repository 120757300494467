<template>
  <div class="student-year">
    <div class="student-year__input">
      <Dropdown
        header="Выберите номинацию"
        placeholder="Выберите из списка"
        :keys="
          this.nominationsList?.map((item) => {
            return item.title;
          })
        "
        :items="
          this.nominationsList?.map((item) => {
            return item.id;
          })
        "
        v-model="formData.project_tree_id"
        :error="v$.formData.project_tree_id?.$errors?.length !== 0"
        :disabled="isDisabled"
      />
    </div>
    <div class="student-year__btn" v-if="!isDisabled">
      <Button @click="saveInfo" :loading="isBtnLoading">
        Сохранить изменения
      </Button>
      <Button secondary v-if="false">Сохранить как черновик</Button>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/Blocks/Dropdown";
import Button from "@/components/Blocks/Button";
import useVuelidate from "@vuelidate/core";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import validations from "@/enums/validations";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "StudentYearIndividualNomination",
  components: { Button, Dropdown },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      isBtnLoading: false,
      formData: {
        project_tree_id: "",
      },
    };
  },
  props: ["currentProject", "type"],
  validations() {
    return {
      formData: validations.studentYearNominationsData,
    };
  },
  computed: {
    fieldsInfo() {
      return trayValidationsTexts.studentYearNominationsData;
    },
    nominationsList() {
      let buffer = JSON.parse(
        JSON.stringify(this.currentProject?.settings?.trees?.data)
      );
      let _buffer = this.currentCategoryId
        ? buffer.filter((item) => {
            return item.category_id === this.currentCategoryId;
          })
        : buffer.filter((item) => {
            return (
              item.category_id === this.currentCategoryData?.info_category?.id
            );
          });
      if (
        this.user?.info_level_education?.id === 15 &&
        process.env.VUE_APP_MODE !== "development"
      ) {
        _buffer = _buffer.filter((item) => item.id !== 112 && item.id !== 175);
      }
      return _buffer;
    },
    isDisabled() {
      let boolean;
      if (Object.values(this.currentCategoryData).length > 0) {
        // if (this.validPeriodForRollback) {
        //   if (this.currentCategoryData?.status?.key === 0) {
        //     boolean = false;
        //   } else boolean = this.currentCategoryData?.status?.key !== 35;
        // } else if (this.validPeriodFor35Status) {
        //   boolean = this.currentCategoryData?.status?.key !== 35;
        // } else return true;
        if (this.validPeriodForRollback) {
          boolean = this.currentCategoryData?.status?.key !== 0;
        } else boolean = true;
      }

      return this.isOldApplication || boolean;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },

    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },

    ...mapState("user", ["user"]),
    ...mapState([
      "currentCategoryData",
      "statementId",
      "currentCategoryId",
      "isOldApplication",
    ]),
  },
  methods: {
    async saveInfo() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }

      this.$store.commit("setNominationValue", [
        `${this.type}NominationValue`,
        this.formData.project_tree_id,
      ]);
      if (this.type === "collective") {
        this.$router.push({ name: "StudentYearCollectiveData" });
      }
      if (this.type === "individual") {
        this.$router.push({ name: "StudentYearIndividualData" });
      }
      if (this.type === "granprix") {
        this.$router.push({ name: "StudentYearGrandPrixData" });
      }
    },
  },
  mounted() {
    this.formData.project_tree_id =
      this.$store.state[`${this.type}NominationValue`];
  },
};
</script>

<style scoped></style>
