<template>
  <div class="dropdown" :class="className">
    <div class="dropdown__label">{{ header }}</div>
    <div
      class="dropdown__value"
      @click="toogleDropdown"
      :class="showDropdown ? 'focus' : ''"
    >
      <Icon class="icon-Warning dropdown__icon--warning"></Icon>
      <Icon class="dropdown__icon" v-if="iconType" :type="iconType" />
      <input
        :size="size"
        :readonly="true"
        :value="selectedKey ?? startValue"
        :placeholder="placeholder"
        v-bind="$attrs"
      />
    </div>
    <div
      class="dropdown__drop"
      v-if="showDropdown"
      v-click-away="
        () => {
          showDropdown = false;
        }
      "
    >
      <div class="dropdown__drop-content">
        <label
          v-for="(item, index) in dropdownItems"
          :key="index"
          :for="`dropdownItem${index}_${uniqComponentId}`"
          @click="select(item)"
        >
          <input
            type="radio"
            :id="`dropdownItem${index}_${uniqComponentId}`"
            :value="item.value"
            v-model="value"
            name="dropdownItem"
          />
          {{ item.key }}
        </label>
      </div>
    </div>
    <div class="form__error" v-if="errorArray && errorArray[0]">
      {{ errorArray[0].$message }}
    </div>
  </div>
</template>

<script>
import { mixin as VueClickAway } from "vue3-click-away";
import uniqueString from "unique-string";
import Icon from "@/components/Blocks/Icon";

export default {
  name: "Dropdown",
  components: { Icon },
  mixins: [VueClickAway],
  data() {
    return {
      showDropdown: false,
      currentValue: "",
      uniqComponentId: uniqueString(),
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    keys: {
      type: Array,
      default: () => [],
    },
    size: Number,
    iconType: String,
    header: [String, Number],
    error: [Boolean, Object],
    disabled: Boolean,
    small: Boolean,
    startValue: String,
    modelValue: [String, Number],
    placeholder: [String, Number],
    errorArray: Array,
    last: Boolean,
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        "dropdown--error": this.error,
        "dropdown--disabled": this.disabled,
        "dropdown--small": this.small,
        "dropdown--last": this.last,
      };
    },
    dropdownItems() {
      return this.items.map((item, index) => {
        return { key: this.keys[index] ?? item, value: item };
      });
    },
    selectedKey() {
      let key = this.dropdownItems.find(
        (item) => item.value === this.value
      )?.key;
      return key ?? this.startValue;
    },
  },
  watch: {
    modelValue(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.showDropdown = false;
      }
      this.currentValue = newValue?.key;
    },
  },
  methods: {
    select(key) {
      this.currentValue = key.key;
      this.value = key.value;
      this.showDropdown = false;
      this.$emit("current-value", key);
    },
    toogleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  position: relative;
  margin-bottom: 11px;
  &--small {
  }
  &--error {
    .dropdown__value {
      border-color: var(--red);
    }
    .dropdown__icon--warning {
      display: block;
    }
  }
  &--disabled {
    pointer-events: none;
    .dropdown__value {
      background: var(--background--disabled);
      input {
        color: var(--input--placeholder);
      }
    }
  }
  &--last {
    .dropdown__drop {
      bottom: 100%;
    }
  }
  &__icon {
    color: #b6b6b6;
    &--warning {
      color: var(--red);
      order: 3;
      display: none;
    }
  }
  &__label {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 6px;
  }
  &__value {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    transition: all 0.3s ease;
    padding: 0 8px;
    border: 1px solid var(--input--border);
    border-radius: 8px;
    outline: 0;
    background-color: var(--white);
    -webkit-appearance: none;
    width: 100%;
    overflow: hidden;
    position: relative;
    gap: 8px;
    box-shadow: none;
    display: flex;

    align-items: center;
    cursor: pointer;
    &:hover {
      border-color: var(--background--main);
    }
    &::after {
      content: "\e90f";
      background-size: contain;
      font-family: icomoon;
      display: block;
      color: var(--color--disabled);
      font-size: 1rem;
      transition: all 0.3s ease-in-out;
      transform: rotate(180deg);
    }
    input {
      flex: 1 1 auto;
      display: inline-block;
      max-width: 100%;
      height: 40px;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      &::-webkit-input-placeholder {
        color: var(--input--placeholder);
      }
      &:-moz-placeholder {
        color: var(--input--placeholder);
      }
      &:-ms-input-placeholder {
        color: var(--input--placeholder);
      }
    }
    &.focus {
      border-color: var(--background--main);
      &::after {
        transform: rotate(0deg);
      }
    }
  }
  &__drop {
    position: absolute;
    left: 0;
    width: 100%;
    border: 1px solid var(--background--main);
    border-radius: 8px;
    background: white;
    margin: 10px 0 0 0;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7rem;
    font-size: 1.6rem;
    overflow: hidden;
    z-index: 1000;
    box-shadow: 0px 8px 20px rgba(24, 24, 24, 0.08);
    &-content {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 300px;
      overflow-scrolling: touch;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      overscroll-behavior: contain;
      padding: 10px 0 10px 0;
      @include scrollbar();
      & label {
        display: block;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        padding: 10px 5px;
        color: black;
        margin: 0 8px;
        border-radius: 8px;
        &:hover {
          background: var(--background--application--review);
        }
      }
      input {
        margin: 0;
      }
      @include scrollbar();
    }
  }
  //@include adaptive(phone) {
  //  &__value {
  //    font-size: 12px;
  //    line-height: 14px;
  //  }
  //  &__drop {
  //    font-size: 12px;
  //    line-height: 14px;
  //  }
  //}
}
</style>
